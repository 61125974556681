import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ExplorerService } from '../../services/explorer.service';

@Component({
  selector: 'app-drops',
  templateUrl: './paymentnetwork.component.html',
  styleUrls: ['./paymentnetwork.component.css']
})
export class PaymentnetworkComponent implements OnInit {

   	 title = 'PBC | Regulated and secure platform';

constructor(private titleService:Title){
  }
 
  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
