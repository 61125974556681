import { Component, OnInit } from '@angular/core';

import { ExplorerService } from '../../../services/explorer.service';

import { TimeFormatter } from '../../ts-classes/TimeFormatter';

import { timeSort, blockHeightSort } from '../../helper-functions/timeSort';

@Component({
  selector: 'app-explorer-seach-bar',
  templateUrl: './explorer-seach-bar.component.html',
  styleUrls: ['./explorer-seach-bar.component.css']
})
export class ExplorerSeachBarComponent implements OnInit {

  text: string = '';

  constructor(private explorerService: ExplorerService) { }

  ngOnInit() {
    this.explorerService.currentSearchText.subscribe(text => this.text = text);
  }

  onInputClearState() {
    this.explorerService.showOnlyLatest();
  }

  onSubmit() {
    this.explorerService.newSearch();
    const search = this.text;
    const searchNumber = Number(this.text);
    if (isNaN(searchNumber)) {
      if (search.length == 64) {
        if (search.substring(0, 5) == '00000') { // Search block hash
          this.explorerService.getBlockByHash(search);
        } else {          // Search transaction
          this.explorerService.getTransactionDetails(search);
        }
      }
      else if (search.length > 25 && search.length < 36) {  // Search address
        console.log({search});
        this.explorerService.getAddress(search);
      } else {
        // Incorrect search
      }
    } else {  // Search block height
      console.log('searching by height');
      this.explorerService.getBlockByHeight(searchNumber);
    }
  }
}
