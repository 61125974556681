import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-apim',
  templateUrl: './apim.component.html',
  styleUrls: ['./apim.component.css']
})
export class ApimComponent implements OnInit {

 	 title = 'PBC | Developers API';

constructor(private titleService:Title){
  }
 
  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
