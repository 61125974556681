import { Component, OnInit } from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
//import {MatDatepickerModule} from '@angular/material/datepicker';

import { MetricService } from '../../services/metric.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-bvxchart',
  templateUrl: './bvxchart.component.html',
  styleUrls: ['./bvxchart.component.css']
})
export class BvxchartComponent implements OnInit {
  chartData;
  chart = [];
  initialDate = new Date(2018, 4, 3)
  todaysDate = new Date();

  minDate:any = new Date(2018, 4, 3);
  maxDate:any = new Date();
  constructor(private metricService: MetricService) { }

  ngOnInit() {
    this.createChart();
  }

  createChart() {
    this.metricService.getReferenceRates()
    .then((data) => {
      this.chartData = data;
      let filteredDates = this.chartData.filter(this.filterChartDates)
       let price = filteredDates.map(fdate => fdate.rate)
       let date = filteredDates.map(fdate => fdate.reg_date)
       this.chart = new Chart('canvas', {
        type: 'line',
        data: {
          labels: date,
          datasets: [
            { 
              data: price,
              borderColor: "white",
			  fill: false
            }
          ]
        },
        options: {
          tooltips: {
            enabled: false
          },
          elements: {
            point: {
              radius:0
            }
          },
          animation: false,
          legend: {
            display: false
          },
          title: {
            display:true,
            text: 'Paiblock coin price'
          },
          scales: {
            xAxes: [{
              display: true
            }],
            yAxes: [{
              display: true
            }],
          }
        }
      });
    })
    .catch((err) => {
      console.log({err});
    });
  }

  filterChartDates = (element) => {
    let elementDate = new Date(element.reg_date);
    if (elementDate > this.minDate && elementDate < this.maxDate) {
      return element;
    }
  }

  OnStartDateChange(event: MatDatepickerInputEvent<Date>) {
    let year = event.value.getFullYear();
    let month = event.value.getMonth();
    let day = event.value.getDate();
    this.minDate = new Date(year,month,day);
    this.createChart();
  }
  OnEndDateChange(event: MatDatepickerInputEvent<Date>) {
    let year = event.value.getFullYear();
    let month = event.value.getMonth();
    let day = event.value.getDate();
    this.maxDate = new Date(year,month,day);
    this.createChart();
  }

}
