import { Component, OnInit } from '@angular/core';
import { ExplorerService } from '../../../services/explorer.service';

@Component({
  selector: 'app-explorer-list',
  templateUrl: './explorer-list.component.html',
  styleUrls: ['./explorer-list.component.css']
})
export class ExplorerListComponent implements OnInit {
  searchStartedState: boolean;
  searchState: boolean;
  latestPuutTransactions;
  latestTransactions;
  latestBlocks;
  blockDetails;
  transactionDetails = null;
  addessDetails;
  transactions;
  qrcode: string;

  constructor(private explorerService: ExplorerService) { }

  ngOnInit() {
    this.explorerService.currentSearchStartedState.subscribe(state => this.searchStartedState = state);

    this.explorerService.currentSearchState.subscribe(state => this.searchState = state);

    this.explorerService.currentLatestPuutTransactions.subscribe(transactions => this.latestPuutTransactions = transactions);

    this.explorerService.currentLatestTransactions.subscribe(transactions => this.latestTransactions = transactions);

    this.explorerService.currentLatestBlocks.subscribe(blocks => this.latestBlocks = blocks);

    this.explorerService.currentBlock.subscribe(block => this.blockDetails = block);

    this.explorerService.currentTransaction.subscribe(transaction => this.transactionDetails = transaction);

    this.explorerService.currentAddress.subscribe(address => 
      this.addessDetails = address);

    this.explorerService.currentTransactions.subscribe(transactions => this.transactions = transactions);
  }

  onInputSetState(text) {
    this.explorerService.newSearch();
    this.explorerService.updateSearchText(text);
  }

  getTransaction(search) {
    this.explorerService.newSearch();
    this.explorerService.getTransactionDetails(search);  
  }

  getBlockByHeight(height) {
    this.explorerService.newSearch();
    this.explorerService.getBlockByHeight(height);
  }

  getBlockByHash(hash) {
    this.explorerService.newSearch();
    this.explorerService.getBlockByHash(hash);
  }

  getAddress(address) {
    this.explorerService.newSearch();
    this.explorerService.getAddress(address);
  }

}
