export function timeSort(a,b) {
  if (a.time < b.time) {
    return -1;
  }
  if (a.time > b.time) {
    return 1;
  } else {
    return 0;
  }
}

export function blockHeightSort(a,b) {
  if (a.height > b.height) {
    return -1;
  }
  if (a.height < b.height) {
    return 1;
  } else {
    return 0;
  }
}