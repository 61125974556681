import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';



const httpOptions = {
  headers: new HttpHeaders({
    'x-api-key': 'c942ffa5-cf9a-4808-8e03-c4874b88fb74',
    'api-secret': 'loGeUzWuWt',
    'Content-Type': 'application/json'
  })
}


@Injectable()
export class MetricService {
  supply;
  data: Observable<any>;

  constructor(private http: HttpClient) { }


  getData(): Observable<any> {
    return of(this.supply);
  }

  getReferenceRates() {
    let URL = "https://pbcchain.cash/data.php";
    /* URL below is for testing
    * let URL = "http://localhost/currencyserver/data.php";
    */
    let promise = new Promise((resolve, reject) => {
      this.http.get(URL)
        .toPromise()
        .then(
          res => { // Success
            if (res == '0 results') {
              reject();
            } else {
              resolve(res);
            }
          }
        );
    });
    return promise;
  }



  getMoneySupply() {
    let URL = "https://api.paiblock.app/chainstate/web3/v2/pbc/getmoneysupply";
    let promise = new Promise<[string]>((resolve, reject) => {
      this.http.get(URL, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            if (res['result'] == null) {
              reject();
            } else {
              this.supply = res['result'];
              resolve(res['result']);
            }
          }
        );
    });
    return promise;
  }
}


