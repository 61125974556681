
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgtUniversalModule } from '@ng-toolkit/universal';

import { MatNativeDateModule, MatInputModule } from '@angular/material';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';

import { QRCodeModule } from 'angularx-qrcode';
import { CookieService } from 'ngx-cookie-service';


import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { FaqComponent } from './components/faq/faq.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AboutComponent } from './components/home/about/about.component';
import { FeaturesComponent } from './components/home/features/features.component';
import { WalletsComponent } from './components/home/wallets/wallets.component';
import { ExchangesComponent } from './components/home/exchanges/exchanges.component';
import { JumbotronComponent } from './components/home/jumbotron/jumbotron.component';
import { GraphicsComponent } from './components/graphics/graphics.component';
import { SourcecodeComponent } from './components/sourcecode/sourcecode.component';
import { BlogComponent } from './components/blog/blog.component';
import { AppRoutingModule } from './app-routing.module';
import { PbcComponent } from './components/pbc/pbc.component';
import { TestnetComponent } from './components/testnet/testnet.component';
import { MainnetComponent } from './components/mainnet/mainnet.component';
import { FooterComponent } from './components/footer/footer.component';
import { SecondaryNavbarComponent } from './components/secondary-navbar/secondary-navbar.component';
import { ExplorerService } from './services/explorer.service';
import { MetricService } from './services/metric.service';
import { BlogService } from './services/blog.service';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { GetCoinsComponent } from './components/get-coins/get-coins.component';
import { ABeginnersGuideAllYouNeedToKnowAboutBitcoinComponent } from './components/blog/a-beginners-guide-all-you-need-to-know-about-bitcoin/a-beginners-guide-all-you-need-to-know-about-bitcoin.component';
import { ACoinIsBornComponent } from './components/blog/a-coin-is-born/a-coin-is-born.component';
import { MainApiComponent } from './components/main-api/main-api.component';
import { CoinVsTokenComponent } from './components/blog/coin-vs-token/coin-vs-token.component';
import { CookieBarComponent } from './components/cookie-bar/cookie-bar.component';
import { PolicyComponent } from './components/policy/policy.component';
import { ExplorerSeachFieldComponent } from './shared/explorer-seach-field/explorer-seach-field.component';
import { ExplorerSeachBarComponent } from './components/explorer/explorer-seach-bar/explorer-seach-bar.component';
import { ExplorerListComponent } from './components/explorer/explorer-list/explorer-list.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { WhitepaperComponent } from './components/whitepaper/whitepaper.component';
import { CareersComponent } from './components/careers/careers.component';
import { ContactComponent } from './components/contact/contact.component';
import { BvxchartComponent } from './components/bvxchart/bvxchart.component';
import { FiveEfficientWaysComponent } from './components/blog/five-efficient-ways/five-efficient-ways.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { DropsComponent } from './components/drops/drops.component';
import { ApiComponent } from './components/api/api.component';
import { ApimComponent } from './components/apim/apim.component';
import { PaymentnetworkComponent } from './components/paymentnetwork/paymentnetwork.component';
import { DaapComponent } from './components/daap/daap.component';
import { FeatureComponent } from './components/feature/feature.component';
import { IeoComponent } from './components/ieo/ieo.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqComponent,
    NavbarComponent,
    AboutComponent,
    FeaturesComponent,
    WalletsComponent,
    ExchangesComponent,
    JumbotronComponent,
    GraphicsComponent,
	SourcecodeComponent,
    BlogComponent,
    PbcComponent,
    TestnetComponent,
    MainnetComponent,
    FooterComponent,
    SecondaryNavbarComponent,
    NotFoundComponent,
    GetCoinsComponent,
    ABeginnersGuideAllYouNeedToKnowAboutBitcoinComponent,
    ACoinIsBornComponent,
    MainApiComponent,
    CoinVsTokenComponent,
    CookieBarComponent,
    PolicyComponent,
    ExplorerSeachFieldComponent,
    ExplorerSeachBarComponent,
    ExplorerListComponent,
    WhitepaperComponent,
    CareersComponent,
    ContactComponent,
    BvxchartComponent,
    FiveEfficientWaysComponent,
    SnackBarComponent,
	DropsComponent,
	ApiComponent,
	ApimComponent,
	PaymentnetworkComponent,
	DaapComponent,
	FeatureComponent,
	IeoComponent,

	


  ],
  imports:[
    CommonModule,
    NgtUniversalModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatInputModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatTooltipModule,
    AppRoutingModule,
	BrowserModule,
    FormsModule,
    HttpClientModule,
    QRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [ExplorerService, MetricService, Title, BlogService, CookieService],
})
export class AppModule { }
