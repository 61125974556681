import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ExplorerService } from '../../services/explorer.service';

@Component({
  selector: 'app-drops',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {

 
 	 title = 'PBC | Features';

constructor(private titleService:Title){
  }
 
  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
