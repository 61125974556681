import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coin-vs-token',
  templateUrl: './coin-vs-token.component.html',
  styleUrls: ['./coin-vs-token.component.css']
})
export class CoinVsTokenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
