import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ExplorerService } from '../../services/explorer.service';

@Component({
  selector: 'app-drops',
  templateUrl: './drops.component.html',
  styleUrls: ['./drops.component.css'],

})
export class DropsComponent implements OnInit {
	
	
	 title = 'PBC | Non-fungible token network';
	 

constructor(private titleService:Title){
  }
 
  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}

