import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { TimeFormatter } from '../components/ts-classes/TimeFormatter';
import { timeSort, blockHeightSort } from '../components/helper-functions/timeSort';


const httpOptions = {
  headers: new HttpHeaders({
    'x-api-key': 'c942ffa5-cf9a-4808-8e03-c4874b88fb74',
    'api-secret': 'loGeUzWuWt',
    'Content-Type': 'application/json'
  })
}


@Injectable({
  providedIn: 'root'
})
export class ExplorerService {

  selectedNet = '';

  private searchStartedState = new BehaviorSubject<boolean>(false);
  currentSearchStartedState = this.searchStartedState.asObservable();  

  private searchState = new BehaviorSubject<boolean>(false);
  currentSearchState = this.searchState.asObservable();

  private searchText = new BehaviorSubject<string>('');
  currentSearchText = this.searchText.asObservable();

  private latestPuutTransactionsSource = new BehaviorSubject<any[]>([]);
  currentLatestPuutTransactions = this.latestPuutTransactionsSource.asObservable();

  private latestTransactionsSource = new BehaviorSubject<any[]>([]);
  currentLatestTransactions = this.latestTransactionsSource.asObservable();

  private latestBlocksSource = new BehaviorSubject<any[]>([]);
  currentLatestBlocks = this.latestBlocksSource.asObservable();

  private BlockDetailSource = new BehaviorSubject<any[]>([]);
  currentBlock = this.BlockDetailSource.asObservable();

  private TransactionDetailSource = new BehaviorSubject<any[]>([]);
  currentTransaction = this.TransactionDetailSource.asObservable();

  private AddressDetailSource = new BehaviorSubject<any[]>([]);
  currentAddress = this.AddressDetailSource.asObservable();

  private transactionsSource = new BehaviorSubject<any[]>([]);
  currentTransactions = this.transactionsSource.asObservable();

  constructor(private http: HttpClient) { }

  setSelectedNet(net:string) {
    this.selectedNet = net;
  }

  updateSearchText(search:string) {
    this.searchText.next(search);
  }

  clearDataSource() {
    this.searchText.next('');
    this.searchStartedState.next(false);
    this.latestPuutTransactionsSource.next([]);
    this.latestTransactionsSource.next([]);
    this.latestBlocksSource.next([]);
    this.BlockDetailSource.next([]);
    this.TransactionDetailSource.next([]);
    this.AddressDetailSource.next([]);
    this.transactionsSource.next([]);
  }

  showOnlyLatest() {
    this.searchText.next('');
    this.BlockDetailSource.next([]);
    this.TransactionDetailSource.next([]);
    this.AddressDetailSource.next([]);
    this.transactionsSource.next([]);
    this.searchStartedState.next(false);   
  }

  newSearch() {
    this.searchStartedState.next(true); 
    this.BlockDetailSource.next([]);
    this.TransactionDetailSource.next([]);
    this.AddressDetailSource.next([]); 
    this.transactionsSource.next([]);    
  }

  private dataGetter(URL:any) {
    let urlRoot = "https://api.paiblock.app/"
    let net = this.selectedNet;
    let fullURL = urlRoot+net+URL;
    this.searchState.next(true);
    let promise = new Promise<[string]>((resolve, reject) => {
      this.http.get(fullURL, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            if (res['result'] == null) {
              reject();
            } else {
              resolve(res['result']);
            }
          }
        )
        .catch(
          err => {
            reject();
          }
        );
    });
    return promise;  
  }

  getLatestPuutTransactions() {
    let URL = "/web3/v2/pbc/gettransactions?address=tp1qx0463wcv4ujw9w8dzv3ddueplhzmuxqjdmr6tl";
    this.dataGetter(URL)
      .then(
        puutTransactions => {
          let fiveTransactions = puutTransactions.reverse().slice(0,5);
          fiveTransactions.forEach(element => {
            element['time'] = TimeFormatter.timeAgoCalc(element['time']);
          });
          this.latestPuutTransactionsSource.next(fiveTransactions);
          this.searchState.next(false);
        }
      ) 
      .catch(
        err => {
          this.searchState.next(false);
        }
      )  
  }

  getLatestTransactions() {
    let URL = "/web3/v2/pbc/listtransactions";
    this.dataGetter(URL)
      .then(
        transactions => {
          transactions.forEach(element => {
            element['time'] = TimeFormatter.timeAgoCalc(element['time']);
          });
          transactions.reverse();
          this.latestTransactionsSource.next(transactions);
          this.searchState.next(false);
        }
      )  
      .catch(
        err => {
          this.searchState.next(false);
        }
      )  
  }

  getLatestBlocks() {
    let latestBlocks = [];
    let URL = "/web3/v2/pbc/getblockcount";
    this.dataGetter(URL)
      .then(
        res => { // Success   
          let blockHeight = Number(res);                   
          for (let i = 0; i < 5; i++) {
            let heightURL = "/web3/v2/pbc/getblockbyheight/" + blockHeight;
            this.dataGetter(heightURL)
              .then(
                hash => { // Success   
                  this.searchState.next(false);
                  if (hash == null) {       
                  } else {
                    let hashURL = "/web3/v2/pbc/getblock?hash=" + hash;
                    this.dataGetter(hashURL)
                      .then(
                        blockByHashResult => {
                          blockByHashResult[0]['time'] = TimeFormatter.timeAgoCalc(blockByHashResult[0]['time']);
                          latestBlocks.push(blockByHashResult[0]);
                          latestBlocks.sort(blockHeightSort);
                          if (i == 4) {
                            this.searchState.next(false);
                            this.latestBlocksSource.next(latestBlocks);
                          }
                        }
                      ) 
                      .catch(
                        err => {
                          this.searchState.next(false);
                        }
                      )   
                  }
                }
              ) 
              .catch(
                err => {
                  this.searchState.next(false);
                }
              ) 
              blockHeight--;
          }
        }
      )
      .catch(
        err => {
          this.searchState.next(false);
        }
      )
  }

  getTransactionDetails(search:string) {
    let URL = "/web3/v2/pbc/getrawtransaction?txid=" + search;
    this.dataGetter(URL)
      .then(
        transaction => {
          transaction['time'] = TimeFormatter.timeAgoCalc(transaction['time']);
          this.TransactionDetailSource.next(transaction);
          this.searchState.next(false);
        }
      )  
      .catch(
        err => {
          this.searchState.next(false);
        }
      )  
  }

  getTransactions(transactions) {
    let allTransactions = [];
    transactions.forEach(transaction => {
      let URL = "/web3/v2/pbc/getrawtransaction?txid=" + transaction.txid;
      this.dataGetter(URL)
        .then(
          res => {
            res['time'] = TimeFormatter.timeAgoCalc(res['time']);
            allTransactions.push(res);
            if (allTransactions.length == transactions.length) {
              this.transactionsSource.next(allTransactions);
              this.searchState.next(false);
            }
          }
        )
        .catch(
          err => {
            this.searchState.next(false);
          }
        )
    });
  }

  

  getBlockByHash(hash:string) {
    let URL = "/web3/v2/pbc/getblock?hash=" + hash;
    this.dataGetter(URL)
      .then(
        block => {
          block[0]['time'] = TimeFormatter.timeAgoCalc(block[0]['time']);
          this.BlockDetailSource.next(block);
          this.searchState.next(false);
        }
      )  
      .catch(
        err => {
          this.searchState.next(false);
        }
      )  
  }

  getBlockByHeight(height:number) {
    let URL = "/web3/v2/pbc/getblockbyheight/" + height;
    this.dataGetter(URL)
      .then(
        hash => { // Success 
          if (hash == null) {      
          } else {
            let nextURL = "/web3/v2/pbc/getblock?hash=" + hash;
            this.dataGetter(nextURL)
              .then(
                block => {
                  block[0]['time'] = TimeFormatter.timeAgoCalc(block[0]['time']);
                  this.BlockDetailSource.next(block);
                  this.searchState.next(false);
                }
              )  
          }
        }
      )  
      .catch(
        err => {
          this.searchState.next(false);
        }
      )  
  }

  getTransactionsByWalletName(wallet) {
    let URL = "/web3/v2/pbc/gettransactions?address=" + wallet;
    this.dataGetter(URL)
      .then(
        transactions => {
          this.getTransactions(transactions);
        }
      )
      .catch(
        err => {
          console.log({err});
        }
      )
  }

  getAddress(address: string) {
    let foundAddress = [{
      address: address
    }];
    let URL = "/web3/v2/pbc/listreceivedbyaddress/" + address;
    this.dataGetter(URL)
      .then(
        wallet => {
          foundAddress[0]['wallet'] = wallet;
          this.getTransactionsByWalletName(wallet);
          let balanceURL = "/web3/v2/pbc/getbalance/" + address;
          this.dataGetter(balanceURL)
            .then(
              balance => {
                foundAddress[0]['balance'] = balance[0]['balance'];
                foundAddress[0]['qrcode'] = "data:image/png;base64,"+balance[0]['qrcode'];
                this.AddressDetailSource.next(foundAddress);
                this.searchState.next(false);
              }
            )
            
        }
      )
      .catch(
        err => {
          this.searchState.next(false);
        }
      )
  }

  getBVXCoins(address) {
    const body = {
      "toAddress": address
    }
    let URL = "https://api.paiblock.app/rubystones/web3/v2/pbc/gettestnetcoins";
    let promise = new Promise<[string]>((resolve, reject) => {
      this.http.post(URL, body, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            this.searchState.next(false);
            if (res['result'] == null) {
              reject();
            } else {
              resolve(res['result']);
            }
          }
        )
        .catch(
          err => {
            reject();
            this.searchState.next(false);
          }
        );
    });
    return promise;
  }

}