import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ExplorerService } from '../../services/explorer.service';

@Component({
  selector: 'app-mainnet',
  templateUrl: './mainnet.component.html',
  styleUrls: ['./mainnet.component.css']
})
export class MainnetComponent implements OnInit {
	  	 title = 'PBC | PBC coin network explorer';

  constructor(private explorerService: ExplorerService, private titleService:Title) {
    this.explorerService.clearDataSource();
    this.explorerService.setSelectedNet('chainstate');
    this.explorerService.getLatestTransactions(); 
    this.explorerService.getLatestBlocks(); 
  }

  ngOnInit() {
	    this.titleService.setTitle(this.title);
  }


}
