import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-five-efficient-ways',
  templateUrl: './five-efficient-ways.component.html',
  styleUrls: ['./five-efficient-ways.component.css']
})
export class FiveEfficientWaysComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
