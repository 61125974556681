import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.css']
})
export class CookieBarComponent implements OnInit {
  isOn: boolean = true;

  constructor(private cookieService: CookieService) {
    if (this.cookieService.get("cookieConsent") === 'true') {
      this.isOn = false;
    }
   }

  ngOnInit() {

  }

  handleClick() {
    this.isOn = false;
    let expiredDate = new Date();
    expiredDate.setDate( expiredDate.getDate() + 7 );
    this.cookieService.set( 'cookieConsent', 'true', expiredDate );
  }

}
