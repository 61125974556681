import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sourcecode',
  templateUrl: './sourcecode.component.html',
  styleUrls: ['./sourcecode.component.css']
})
export class SourcecodeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
