import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ExplorerService } from '../../services/explorer.service';

@Component({
  selector: 'app-ieo',
  templateUrl: './ieo.component.html',
  styleUrls: ['./ieo.component.css']
})
export class IeoComponent implements OnInit {

  	 title = 'PBC | PBC coin distributed';

constructor(private titleService:Title){
  }
 
  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
