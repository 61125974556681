export class TimeFormatter {
  static currentTime() {
    let d = new Date();
    let n = d.getTime();
    return n;
  }

  static timeAgoCalc(time: string) {
    if (time == "") {
      return 'Not yet confirmed';
    }
    let milli = Date.parse(new Date(time).toString())
    let current = this.currentTime();
    let difference = current-milli;
    

    let seconds = Number((difference / 1000).toFixed());
    let minutes = Math.floor(Number((difference / (1000 * 60))));
    let hours = Math.floor(Number((difference / (1000 * 60 * 60)).toFixed(1)));
    let days = Number((difference / (1000 * 60 * 60 * 24)).toFixed());
    let months = Number((difference / (1000 * 60 * 60 * 24 * 30)).toFixed());
    let years = Number((difference / (1000 * 60 * 60 * 24 * 30 * 12)).toFixed(1));
    if (years >= 1) {
      if (years < 2) {
        return years+' year';
      } else {
        return years+' years';
      }
    } 
    else if (months >= 1) {
      if (months < 2) {
        return months+' month';
      } else {
        return months+' months';
      }
    }
    else if (days >= 1) {
      if (days < 2) {
        return days+' day';
      } else {
        return days+' days';
      }
    }
    else if (hours >= 1) {
      if (hours < 2) {
        return hours+' hour';
      } else {
        return hours+' hours';
      }
    }
    else if (minutes >= 1) {
      if (minutes < 2) {
        return minutes+' minute';
      } else {
        return minutes+' minutes';
      }
    } else {
      if (seconds > 1) {
        return seconds+' seconds';
      } else {
        return seconds+' second';
      }
    }
  }
}

