import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BlogComponent } from './components/blog/blog.component';


import { ABeginnersGuideAllYouNeedToKnowAboutBitcoinComponent } from './components/blog/a-beginners-guide-all-you-need-to-know-about-bitcoin/a-beginners-guide-all-you-need-to-know-about-bitcoin.component';

import { ACoinIsBornComponent } from './components/blog/a-coin-is-born/a-coin-is-born.component';
import { CoinVsTokenComponent } from './components/blog/coin-vs-token/coin-vs-token.component';
import { PbcComponent } from './components/pbc/pbc.component';
import { FaqComponent } from './components/faq/faq.component';
import { PolicyComponent } from './components/policy/policy.component';
import { GraphicsComponent } from './components/graphics/graphics.component';
import { SourcecodeComponent } from './components/sourcecode/sourcecode.component';
import { MainnetComponent } from './components/mainnet/mainnet.component';
import { MainApiComponent } from './components/main-api/main-api.component';
import { TestnetComponent } from './components/testnet/testnet.component';
import { GetCoinsComponent } from './components/get-coins/get-coins.component';
import { CareersComponent } from './components/careers/careers.component';
import { ContactComponent } from './components/contact/contact.component';
import { WhitepaperComponent } from './components/whitepaper/whitepaper.component';
import { FiveEfficientWaysComponent } from './components/blog/five-efficient-ways/five-efficient-ways.component';
import { DropsComponent } from './components/drops/drops.component';
import { ApiComponent } from './components/api/api.component';
import { ApimComponent } from './components/apim/apim.component';
import { PaymentnetworkComponent } from './components/paymentnetwork/paymentnetwork.component';
import { DaapComponent } from './components/daap/daap.component';
import { IeoComponent } from './components/ieo/ieo.component';
import { FeatureComponent } from './components/feature/feature.component';
import { NotFoundComponent } from './components/not-found/not-found.component';



const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'a-beginners-guide-all-you-need-to-know-about-bitcoin', component: ABeginnersGuideAllYouNeedToKnowAboutBitcoinComponent},
  {path: 'a-coin-is-born', component: ACoinIsBornComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'pbc', component: PbcComponent},
 // {path: 'faq', component: FaqComponent},
  {path: 'policy', component: PolicyComponent},
  {path: 'graphics', component: GraphicsComponent},
  {path: 'sourcecode', component: SourcecodeComponent},
  {path: 'chainstate', component: MainnetComponent},
  {path: 'testnet-api', component: ApiComponent},
  {path: 'mainnet-api', component: MainApiComponent},
  {path: 'rubystones', component: TestnetComponent},
  {path: 'getcoins', component: GetCoinsComponent},
  {path: 'coin-vs-token', component: CoinVsTokenComponent},
  {path: 'whitepaper', component: WhitepaperComponent},
  //{path: 'careers', component: CareersComponent},
 // {path: 'contact', component: ContactComponent},
  {path: 'five-efficient-ways', component: FiveEfficientWaysComponent},
  {path: 'drops', component: DropsComponent},
  {path: 'api', component: ApiComponent},
  {path: 'apim', component: ApimComponent},
    {path: 'paymentnetwork', component: PaymentnetworkComponent},
	  {path: 'daap', component: DaapComponent},
	 {path: 'feature', component: FeatureComponent},
	  {path: 'ieo', component: IeoComponent},
  {path: '**', component: NotFoundComponent},

  
]

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes)
  ],
})
export class AppRoutingModule { }
