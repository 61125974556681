import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exchanges',
  templateUrl: './exchanges.component.html',
  styleUrls: ['./exchanges.component.css']
})
export class ExchangesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
