import { SwUpdate } from '@angular/service-worker';
import { Component, OnInit, HostListener  } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { MetricService } from './services/metric.service';
import { SeoService } from './services/seo.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'PBC';
    scrollBtnActive: boolean = false;
    constructor(private swUpdate: SwUpdate, private meta: Meta, private metricService: MetricService, private cookieService: CookieService, private seoService: SeoService) {
        
        this.meta.updateTag({ name: 'keywords', content: 'pbc, paiblock, coin, bitcoin, crypto, blockchain, cryptocurrency' });
        /*
        this.meta.addTag({ name: 'author', content: 'Paiblockcoin' });
        this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
        this.meta.addTag({ charset: 'UTF-8' });
        */
    }

    ngOnInit() {
        // this.createLinkForCanonicalURL();
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe((evt) => {
                console.log('service worker updated');
            });

            this.swUpdate.checkForUpdate().then(() => {
                // noop
            }).catch((err) => {
                console.error('error when checking for update', err);
            });
        }
    }

    createLinkForCanonicalURL() {
        this.seoService.createLinkForCanonicalURL();
      } 

    @HostListener("window:scroll", ["$event"])
    onWindowScroll() {
        //In chrome and some browser scroll is given to body tag
        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        /*
        let max = document.documentElement.scrollHeight;
        let half = pos / max;
        console.log({pos});
        let halfRounded = Math.round(half * 1e2) / 1e2;
        */
        //if (halfRounded > 0.2) {
        if (pos > 1200) {
            this.scrollBtnActive = true;
        } else {
            this.scrollBtnActive = false;
        }
    }


    onActivate(event) {
        window.scroll(0, 0);
    }

    toTop() {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 80);
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 1);
    }
}
