import { Component, OnInit } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { Title } from '@angular/platform-browser';

import { MetricService } from '../../services/metric.service';

@Component({
  selector: 'app-pbc',
  templateUrl: './pbc.component.html',
  styleUrls: ['./pbc.component.css']
})


export class PbcComponent implements OnInit {
  mode = 'buffer';
  loading: boolean = true;
  moneySupply: any;
  circulatingSupply: number;
  marketCap: number;

  data: any;
  
   	 title = 'PBC | A coin for all';


  constructor(private metricService: MetricService, private titleService:Title) { }
  
  ngOnInit() {
    
    this.metricService.getData().subscribe(data => {
      this.moneySupply = data;
      if (this.moneySupply !== undefined) {
        this.circulatingSupply = Number(this.moneySupply.total_amount);
        this.marketCap = this.circulatingSupply*4797.15;
        this.loading = false;  
      }     
    });
    
    
    if (this.circulatingSupply == undefined) {
      this.metricService.getMoneySupply()
      .then((data) => {
         this.moneySupply = data;
         this.circulatingSupply = Number(this.moneySupply.total_amount);
         this.marketCap = this.circulatingSupply*4797.15;
         this.loading = false;
      })
      .catch((err) => {
        console.log({err});
      });
    } 

  this.titleService.setTitle(this.title);

  }


}
