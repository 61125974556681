import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { MatSnackBar } from '@angular/material';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

import { ExplorerService } from '../../services/explorer.service';

@Component({
  selector: 'app-get-coins',
  templateUrl: './get-coins.component.html',
  styleUrls: ['./get-coins.component.css']
})
export class GetCoinsComponent implements OnInit {
  
  searchStartedState: boolean = false;
  text: string = '';
  title = 'PBC | Get Paiblock coins';

  constructor(private explorerService: ExplorerService, public snackBar: MatSnackBar, private titleService:Title) {
    this.explorerService.clearDataSource();
    this.explorerService.setSelectedNet('rubystores');
    this.explorerService.getLatestPuutTransactions();
    this.explorerService.currentSearchStartedState.subscribe(state => this.searchStartedState = state);
  }

  ngOnInit() { 
     this.titleService.setTitle(this.title);

  }


  onSubmit() {
    const toAddress = this.text;
    this.explorerService.getBVXCoins(toAddress)
      .then((data) => {
        this.text = '';
        this.snackBar.open('Coins have been sent!', '', {
          duration: 3000,
        });
      })
      .catch((err) => {
        
        // this.snackBar.openFromComponent(SnackBarComponent, {
        //   duration: 3000,
        //   data: 'Some data'
        // });
        

        this.snackBar.open('Test coins could not be sent. Please check if address is correct', '', {
          duration: 3500,
        });
        
      });
  }


  onClearState() {
    this.text = '';
  }

}
