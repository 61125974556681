import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ExplorerService } from '../../services/explorer.service';

@Component({
  selector: 'app-testnet',
  templateUrl: './testnet.component.html',
  styleUrls: ['./testnet.component.css']
})
export class TestnetComponent implements OnInit {
	 title = 'PBC | PBC coin testnet..';

  constructor(private explorerService: ExplorerService, private titleService:Title) { 
    this.explorerService.clearDataSource();
    this.explorerService.setSelectedNet('rubystones');
    this.explorerService.getLatestTransactions(); 
    this.explorerService.getLatestBlocks(); 
  }

  ngOnInit() {
	     this.titleService.setTitle(this.title);
  }


}
